import { observer } from 'mobx-react';
import {
  FC,
  lazy,
  Suspense,
} from 'react';

import {
  Route,
  Routes
} from 'react-router-dom';

import { View } from 'views/ui-kit/View';
import dateStore from '../dateStore';
import SplashScreenPage from '../views/pages/SplashScreenPage/SplashScreenPage';


import styles from './AppNavigator.module.scss';

const OnboardingPage = lazy(() => import('../views/pages/OnboardingPage/OnboardingPage'));
const MainStack = lazy(() => import('./stacks/MainStack'));

export const AppNavigator: FC = observer(() => {
  return (
    <Suspense fallback={<SplashScreenPage/>}>
      <View className={styles.page}>
        <Routes>
          {dateStore.hasOnboarded ? (
              <Route path="/*" element={<MainStack/> }/>
          ) : (
            <Route path="/" element={<OnboardingPage/>}/>
          )}
        </Routes>
      </View>
    </Suspense>
  );
});
