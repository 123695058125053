import { DetailedHTMLProps, forwardRef, MouseEventHandler } from 'react';

import { classNames } from 'utils';

import styles from './View.module.scss';

interface IProps extends Omit<DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'onClick'> {
  onPress?: MouseEventHandler<HTMLDivElement>;
}

export const View = forwardRef<HTMLDivElement, IProps>(({ onPress, className, children, ...rest }, ref) => {
  return (
    <div className={classNames(styles.flex, className)} ref={ref} onClick={onPress} {...rest}>
        {children}
    </div>
  );
});
