import { makeAutoObservable } from 'mobx';
import {
  Month,
  PythagorasNumbers
} from './helper';
import { Sex } from './types';

class DateStore {
  day: string = '01';
  month: Month = Month.january;
  year: string = '1985';
  sex: Sex = Sex.MALE;
  username: string = '';
  pythagorasNumber: PythagorasNumbers = {
    birthDateWithoutZero: "111",
    firstNumber: 0,
    secondNumber: 0,
    thirdNumber: 0,
    fourthNumber: 0,
    digitCounts: { "1": 0 },
    origin: 0
  };
  destinyNumber: number = 0;
  arcanaNumber: number = 0;
  profileNumber: string = '';
  hasOnboarded: boolean = false;

  constructor() {
    makeAutoObservable(this);
    this.loadState();
  }

  setDay(newDay: string) {
    this.day = newDay;
    this.saveState();
  }

  setMonth(newMonth: Month) {
    this.month = newMonth;
    this.saveState();
  }

  setYear(newYear: string) {
    this.year = newYear;
    this.saveState();
  }

  setSex(newSex: Sex) {
    this.sex = newSex;
    this.saveState();
  }

  setUsername(newName: string) {
    this.username = newName;
    this.saveState();
  }

  setPythagorasNumber(newPythagorasNumber: PythagorasNumbers) {
    this.pythagorasNumber = newPythagorasNumber;
    this.saveState();
  }

  setDestinyNumber(newDestinyNumber: number) {
    this.destinyNumber = newDestinyNumber;
    this.saveState();
  }

  setArcanaNumber(newArcanaNumber: number) {
    this.arcanaNumber = newArcanaNumber;
    this.saveState();
  }

  setProfileNumber(newProfileNumber: string) {
    this.profileNumber = newProfileNumber;
    this.saveState();
  }

  setOnboarded(_hasOnboarded: boolean) {
    this.hasOnboarded = _hasOnboarded;
    this.saveState();
  }

  saveState() {
    const state = {
      day: this.day,
      month: this.month,
      year: this.year,
      sex: this.sex,
      username: this.username,
      pythagorasNumber: this.pythagorasNumber,
      destinyNumber: this.destinyNumber,
      arcanaNumber: this.arcanaNumber,
      profileNumber: this.profileNumber,
      hasOnboarded: this.hasOnboarded,
    };
    localStorage.setItem('dateState', JSON.stringify(state));
  }

  loadState() {
    const state = JSON.parse(localStorage.getItem('dateState') || "{}");
    if (state) {
      console.log(state)
      this.day = state.day || '10';
      this.month = state.month || Month.january;
      this.year = state.year || '1985';
      this.sex = state.sex || Sex.MALE;
      this.username = state.username || '';
      this.pythagorasNumber = state.pythagorasNumber;
      this.destinyNumber = state.destinyNumber || 0;
      this.arcanaNumber = state.arcanaNumber || 0;
      this.profileNumber = state.profileNumber || '';
      this.hasOnboarded = state.hasOnboarded || false;
    } else {
      this.setDefaultValues();
    }
  }

  setDefaultValues() {
    this.day = '10';
    this.month = Month.january;
    this.year = '1985';
    this.sex = Sex.MALE;
    this.username = '';
    this.pythagorasNumber = {
      birthDateWithoutZero: "111",
      firstNumber: 0,
      secondNumber: 0,
      thirdNumber: 0,
      fourthNumber: 0,
      digitCounts: { "1": 0 },
      origin: 0
    };
    this.destinyNumber = 0;
    this.arcanaNumber = 0;
    this.profileNumber = '';
    this.saveState();
  }
}

const dateStore = new DateStore();
export default dateStore;
