export enum Month {
  january = 'january',
  february = 'february',
  march = 'march',
  april = 'april',
  may = 'may',
  june = 'june',
  july = 'july',
  august = 'august',
  september = 'september',
  october = 'october',
  november = 'november',
  december = 'december'
}

function getMonthNumber(month: Month): string | undefined {
  const months: Record<Month, string> = {
    january: '01', february: '02', march: '03', april: '04', may: '05', june: '06',
    july: '07', august: '08', september: '09', october: '10', november: '11', december: '12'
  };

  const monthKey = month.toLowerCase() as Month;
  return months[monthKey];
}

function buildBirthDate(month: Month, day: string, year: string) {
  const monthNumber = getMonthNumber(month);

  // Concatenate day, month, and year
  return day + monthNumber + year;
}

export const calculateDestinyNumber = (day: string, month: Month, year: string): number =>  {
  const birthDate = buildBirthDate(month, day, year);

  let sum = birthDate.split('').map(Number).reduce((acc, digit) => acc + digit, 0);
  while (sum > 9) {
    sum = sum.toString().split('').reduce((acc, digit) => acc + Number(digit), 0);
  }

  return sum;
}

export type PythagorasNumbers = {
  birthDateWithoutZero: string;
  firstNumber: number;
  secondNumber: number;
  thirdNumber: number;
  fourthNumber: number;
  digitCounts: Record<string, number>;
  origin: number;
}

export const calculatePythagorasNumbers = (day: string, month: Month, year: string): PythagorasNumbers => {
  const birthDate = buildBirthDate(month, day, year);
  // Remove zeros and convert to array of numbers
  const numbersWithoutZero = birthDate.split('.').join('').split('').map(Number).filter(n => n !== 0);

  // First Pythagoras number: sum of all digits without zeros
  const firstNumber = numbersWithoutZero.reduce((a, b) => a + b, 0);

  // Second Pythagoras number (Destiny number): sum of digits of the first number
  let secondNumber = firstNumber.toString().split('').reduce((a, b) => a + parseInt(b, 10), 0);

  while (secondNumber > 9) {
    secondNumber = secondNumber.toString().split('').reduce((a, b) => a + parseInt(b, 10), 0);
  }
  // Third Pythagoras number: double the first digit subtracted from the first number
  const thirdNumber = firstNumber - (2 * numbersWithoutZero[0]);

  // Fourth Pythagoras number: sum of digits of the third number (ignore zeros)
  const fourthNumber = thirdNumber.toString().split('').reduce((a, b) => a + (b === '0' ? 0 : parseInt(b, 10)), 0);

  // Concatenate all numbers and count occurrences of each digit from 1 to 9
  const concatenatedNumbers = [numbersWithoutZero.join(''), firstNumber, secondNumber, thirdNumber, fourthNumber].join('');
  const digitCounts: Record<string, number> = {};
  for (let i = 1; i <= 9; i++) {
    digitCounts[i.toString()] = concatenatedNumbers.split(i.toString()).length - 1;
  }

  return {
    birthDateWithoutZero: numbersWithoutZero.join(''),
    firstNumber,
    secondNumber,
    thirdNumber,
    fourthNumber,
    digitCounts,
    origin: digitCounts[secondNumber.toString()]
  };
}

export const calculateArkanaNumber = (day: string): number => {
  const _day = parseInt(day, 10);

  let index;
  if (_day <= 22) {
    index = _day;
  } else {
    index = _day.toString().split('').reduce((acc, digit) => acc + parseInt(digit, 10), 0);
  }

  if (index < 0 || index >= 23) {
    console.log('Invalid date or text not available'); //@TODO or handle as needed
  }

  return index
}

export const calculateProfileNumber = (destinyNumber: number, pythagorasNumber: number, arkanaNumber: number): string => {
// Concatenate the numbers without spaces
  const fullNumber = `${destinyNumber}${pythagorasNumber}${arkanaNumber}`;

  // Sum the individual digits of the full number
  // Reduce the sum to a single digit if necessary
  let shortNumber = fullNumber.split('').reduce((acc, digit) => acc + parseInt(digit, 10), 0);
  while (shortNumber > 9) {
    shortNumber = shortNumber.toString().split('').reduce((acc, digit) => acc + parseInt(digit, 10), 0);
  }

  return `${fullNumber}/${shortNumber}`;
}
