import { Grid } from '@mui/material';
import {
  FC
} from 'react';
import styles from './SplashScreenPage.module.scss';

const SplashScreenPage: FC = () => {
  return (
    <Grid className={styles.container}>
      <img src="../../../assets/logo_main.svg" alt="img"/>
    </Grid>
  );
}

export default SplashScreenPage;
