import {
  createTheme,
  ThemeProvider
} from '@mui/material';
import {
  FC,
  Suspense
} from 'react';
import './App.scss';
import { BrowserRouter } from 'react-router-dom';
import { AppNavigator } from './navigation/AppNavigator';

const theme = createTheme({
  typography: {
    h1: {
      color: '#383838',
      fontSize: '40px',
      fontWeight: '400',
      lineHeight: '100.045%',
      '&.light': {
        color: '#FFFFFF'
      },
      '&.text-center': {
        textAlign: 'center'
      }
    },
    h2: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      color: '#383838',
      fontSize: '17px',
      fontWeight: '600',
      lineHeight: 'normal',
      textTransform: 'none',
      '&.light': {
        color: '#FFFFFF'
      },
      '&.text-center': {
        textAlign: 'center'
      }
    },
    h3: {
      color: '#FFFFFF',
      fontSize: '28px',
      fontWeight: '400',
      lineHeight: '100.045%',
      fontFamily: 'Illuminate, sans-serif',
    },
    body1: {
      color: '#383838',
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: 'normal',
      '&.light': {
        color: '#FFFFFF'
      },
      '&.text-center': {
        textAlign: 'center'
      }
    }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Illuminate-Medium, sans-serif',
        },
        h3: {
          fontFamily: 'Illuminate-Light, sans-serif',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          border: '1px solid #FF6440',
          boxShadow: 'none',
          backgroundColor: 'transparent',
        },
        root: {
          // Some CSS
          display: 'flex',
          minWidth: 'unset',
          height: '50px',
          fontSize: '17px',
          justifyContent: 'center',
          alignItems: 'center',
          textTransform: 'none',
          alignContent: 'center',
          fontWeight: '600',
          gap: '5px',
          borderRadius: '15px',
          background: '#FFFFFF',
          boxShadow: '0px 10px 15px 0px rgba(133, 98, 54, 0.25)',
          color: '#383838',
          margin: '0 auto',
          fontFamily: 'Illuminate-Medium, sans-serif',
          '&:hover, &:focus, &:active': {
            background: '#FFFFFF'
          },
          '&.fixed': {
            width: '261px'
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          margin: '0',
          lineHeight: 'normal',
          minHeight: 'auto'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          width: '100%',
          fontFamily: 'Illuminate, sans-serif'
        },
        icon: {
          display: 'none',
          '.sexSelect &': {
            display: 'block'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          height: '50px',
          fontWeight: '500',
          color: '#383838',
          borderRadius: '15px',
          padding: '16px',
          minWidth: '80px',
          fontFamily: 'Illuminate, sans-serif'
        },
        notchedOutline: {
          borderColor: '#F3ECE3'
        },
        input: {
          padding: '0'
        },
        multiline: {
          height: 'auto'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%'
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&.rate': {
            height: '60px',
            width: '60px',
            filter: 'drop-shadow(0px 0 10px rgba(176, 89, 38, 0.35))'
          },
          '&.rotated': {
            transform: 'rotate(180deg)'
          },
          '&.yellow': {
            color: '#FFB78D'
          }
        }
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
         gap: '10px'
        }
      }
    },
    MuiStep: {
      styleOverrides: {
        root: {
          flex: 'none',
          padding: '0'
        }
      }
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          display: 'none'
        }
      }
    },
  }
});

const App: FC = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<div>Loading...</div>}>
          <AppNavigator/>
        </Suspense>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
